import { useCallback, useState, forwardRef, useImperativeHandle, useRef, KeyboardEvent, FocusEvent, useEffect, ClipboardEvent } from 'react';
import { FroalaInputProps } from './FroalaInput';
import { useTranslation } from 'react-i18next';
import { sanitizeHTML } from '../../../utils/RichTextUtils';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { DynamicDataInterfaceHandle } from './InputWithDynamicData';
import TranslatableInputButtons from '../TranslatableInputButtons';

const domParser = new DOMParser();

type ContentEditableDivInputProps = FroalaInputProps;

const ContentEditableDivInput = forwardRef<DynamicDataInterfaceHandle, ContentEditableDivInputProps>(function ContentEditableDivInput(props, ref) {
  const {
    i18n: { language },
  } = useTranslation();
  const [editorLanguage, setEditorLanguage] = useState(language);
  const initialValue =
    (props.enableLanguageToggle ? props.translations?.[editorLanguage]?.[props.translationKey] || props.initialValue : props.initialValue) ?? '';
  const [model, setModel] = useState(initialValue ?? '');
  const [hasFocus, setHasFocus] = useState(false);
  const { label, enableLanguageToggle } = props;
  const [savedPosition, setSavedPosition] = useState<number | null>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const prevLang = useRef(editorLanguage);
  useEffect(() => {
    if (prevLang.current !== editorLanguage) {
      prevLang.current = editorLanguage;
      setModel(initialValue ?? '');
    }
  }, [editorLanguage, initialValue]);

  const emitUpdate = useCallback(
    (value?: string) => {
      if (enableLanguageToggle) {
        const result = {
          ...props.translations,
          [editorLanguage]: { ...(props.translations[editorLanguage] || {}), [props.translationKey]: value ?? model },
        };
        props.onTranslationsChange(result);
      }

      props.onTextChange?.(value ?? model);
    },
    [enableLanguageToggle, editorLanguage, model, props],
  );

  useImperativeHandle(
    ref,
    () => ({
      savePosition: () => {
        // Save the current position of the cursor, using a null character to mark the position inside the html
        // This is later then used to insert placeholders at the correct position
        const range = window.getSelection()!.getRangeAt(0).cloneRange();
        range.insertNode(document.createTextNode('\0'));
        const clonedRange = range.cloneRange();
        clonedRange.selectNodeContents(inputRef.current!);
        clonedRange.setEnd(range.endContainer, range.endOffset);
        const tmp = document.createElement('div');
        tmp.appendChild(clonedRange.cloneContents());
        const innerHtml = tmp.innerHTML.replaceAll(/&(?:#[0-9]+|#[xX][0-9a-fA-F]+|[a-zA-Z]+);/g, ' ');
        const pos = innerHtml.indexOf('\0');
        setSavedPosition(pos);
        range.deleteContents(); //remove the null character again
      },
      insertPlaceholder: (placeholder: string) => {
        const incommingId = domParser
          .parseFromString(placeholder, 'text/html')
          .body.querySelector('[data-placeholder]')!
          .getAttribute('data-placeholder');

        setModel((prev) => {
          const str = prev.replaceAll(/&.+?;/g, ' ');
          const html = domParser.parseFromString(str, 'text/html');
          const existing = html.querySelector(`[data-placeholder="${incommingId}"]`);

          if (existing) {
            existing.outerHTML = placeholder;
            const result = html.body.innerHTML;
            emitUpdate(result);
            return result;
          } else {
            const start = str.substring(0, savedPosition ?? 0);
            const end = str.substring((savedPosition ?? 0) + 1); // +1 to skip the `/` at the saved position
            const result = start + placeholder + end;
            emitUpdate(result);
            return result;
          }
        });
        inputRef.current?.focus();
      },
      removePlaceholder: (placeholder: string) => {
        setModel((prev) => {
          const html = domParser.parseFromString(prev, 'text/html');
          const existing = html.querySelector(`[data-placeholder="${placeholder}"]`);

          if (existing) {
            existing.remove();
            const result = html.body.innerHTML;
            emitUpdate(result);
            return result;
          }

          return prev;
        });
      },
    }),
    [emitUpdate, savedPosition],
  );

  const onChangeInternal = useCallback(
    (event: ContentEditableEvent) => {
      const value = event.currentTarget.innerHTML;
      const sanitized = sanitizeHTML(value);
      emitUpdate(sanitized);
      setModel(sanitized);
    },
    [emitUpdate],
  );

  const onKeyDownInternal = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (props.singleLine && e.key === 'Enter') {
        e.preventDefault();
        return;
      }

      const selection = window.getSelection();

      if (e.key === 'Backspace') {
        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          const startContainer = range.startContainer as HTMLElement;

          // Check if the cursor is at the start of a custom span and remove the span if necessary
          if (startContainer.nodeType === Node.TEXT_NODE && range.startOffset === 0) {
            const previousSibling = startContainer.previousSibling;
            if (previousSibling && previousSibling.nodeName === 'SPAN' && (previousSibling as HTMLElement).getAttribute('data-placeholder')) {
              e.preventDefault();
              previousSibling.remove();
            }
          } else if (
            startContainer.nodeType === Node.ELEMENT_NODE &&
            startContainer.childNodes[range.startOffset - 1]?.nodeName === 'SPAN' &&
            (startContainer.childNodes[range.startOffset - 1] as HTMLElement).getAttribute('data-placeholder')
          ) {
            e.preventDefault();
            startContainer.childNodes[range.startOffset - 1].remove();
          } else if (
            startContainer.nodeType === Node.ELEMENT_NODE &&
            startContainer.childNodes.length === 1 &&
            startContainer.childNodes[0].nodeName === 'SPAN' &&
            (startContainer.childNodes[0] as HTMLElement).getAttribute('data-placeholder')
          ) {
            e.preventDefault();
            startContainer.childNodes[0].remove();
          }
        }
      }

      if (e.key === 'Delete') {
        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          const startContainer = range.startContainer as HTMLElement;

          // Check if the cursor is at the end of a custom span and remove the span if necessary
          if (startContainer.nodeType === Node.TEXT_NODE && range.startOffset === startContainer.textContent?.length) {
            const nextSibling = startContainer.nextSibling;
            if (nextSibling && nextSibling.nodeName === 'SPAN' && (nextSibling as HTMLElement).getAttribute('data-placeholder')) {
              e.preventDefault();
              nextSibling.remove();
            }
          } else if (
            startContainer.nodeType === Node.ELEMENT_NODE &&
            startContainer.childNodes[range.startOffset]?.nodeName === 'SPAN' &&
            (startContainer.childNodes[range.startOffset] as HTMLElement).getAttribute('data-placeholder')
          ) {
            e.preventDefault();
            startContainer.childNodes[range.startOffset].remove();
          } else if (
            startContainer.nodeType === Node.ELEMENT_NODE &&
            startContainer.childNodes.length === 1 &&
            startContainer.childNodes[0].nodeName === 'SPAN' &&
            (startContainer.childNodes[0] as HTMLElement).getAttribute('data-placeholder')
          ) {
            e.preventDefault();
            startContainer.childNodes[0].remove();
          }
        }
      }

      props?.onKeyDown?.(e);
    },
    [props],
  );

  const onFocusRef = useRef(props?.onFocus);
  const onBlurRef = useRef(props?.onBlur);
  useEffect(() => {
    onFocusRef.current = props?.onFocus;
    onBlurRef.current = props?.onBlur;
  }, [props?.onBlur, props?.onFocus]);

  const onFocusInternal = useCallback((e: FocusEvent<HTMLDivElement>) => {
    setHasFocus(true);
    onFocusRef.current?.(e);
  }, []);

  const onBlurInternal = useCallback((e: FocusEvent<HTMLDivElement>) => {
    onBlurRef.current?.(e);
  }, []);

  useEffect(() => {
    if (!hasFocus) return;

    const handler = (e: MouseEvent) => {
      if (!wrapperRef.current?.contains(e.target as Node)) {
        setHasFocus(false);
      }
    };

    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [hasFocus]);

  const onPaste = useCallback((e: ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const text = e.clipboardData ? e.clipboardData.getData('text/plain') : '';

    const selection = document.getSelection();
    if (!selection) return;

    const range = selection.getRangeAt(0);
    range.deleteContents(); // remove current selection
    range.insertNode(new Text(text)); // insert text
    range.collapse(); // select nothing
    selection.removeAllRanges(); // position caret after inserted text
    selection.addRange(range); // show caret
  }, []);

  return (
    <div key={editorLanguage} ref={wrapperRef} className="relative mt-9 w-full">
      <div className="text-color-3 text-dpm-12 absolute left-0 top-0 -mt-5 transition-opacity duration-150 ease-in-out">{label}</div>
      <div className={`${hasFocus ? 'border-accent-1' : 'border-primary-1'} flex cursor-text items-start rounded-lg border-2 bg-white`}>
        <ContentEditable
          innerRef={inputRef}
          html={model}
          onChange={onChangeInternal}
          className="text-primary-1 placeholder-gray-2 w-full rounded-lg bg-white px-2 py-2 outline-none"
          onKeyDown={onKeyDownInternal}
          onFocus={onFocusInternal}
          onBlur={onBlurInternal}
          onPaste={onPaste}
        />
        {hasFocus && enableLanguageToggle && (
          <div className="mt-[0.4rem] flex flex-shrink-0">
            <TranslatableInputButtons onChange={setEditorLanguage} selected={editorLanguage} />
          </div>
        )}
      </div>
    </div>
  );
});

export default ContentEditableDivInput;
