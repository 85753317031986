import { FC, useEffect, useMemo, useState } from 'react';
import { Risk, riskRatingOptions } from '../../../models/Risk';
import { useTranslation } from 'react-i18next';
import DropdownSelect, { DropdownSize } from '../../shared/form-control/DropdownSelect';
import { Option } from '../../Option';
import { RiskRating } from '../../../utils/RiskUtils';
import CondencedRiskItem from './CondencedRiskItem';

type CondensedRisksProps = {
  risks: Risk[];
  viewOnly?: boolean;
};

const CondensedRisks: FC<CondensedRisksProps> = (props) => {
  const { risks, viewOnly } = props;
  const {
    t,
    i18n: { language },
  } = useTranslation(['form', 'risk']);
  const allRiskOption = useMemo(
    () => ({
      id: '',
      text: t('risk:risk-rating.all'),
      value: '',
    }),
    [t],
  );
  const [selectedRatingFilter, setSelectedRatingFilter] = useState<Option<string, RiskRating | string>>(allRiskOption);
  const sortingOptions: Option<string, string>[] = useMemo(
    () => [
      {
        id: 'default',
        text: t('risk:sorting.default'),
        value: 'default',
      },
      {
        id: 'recent',
        text: t('risk:sorting.recent'),
        value: 'recent',
      },
      {
        id: 'id-asc',
        text: t('risk:sorting.id-asc'),
        value: 'id-asc',
      },
      {
        id: 'id-desc',
        text: t('risk:sorting.id-desc'),
        value: 'id-desc',
      },
      {
        id: 'rating-desc',
        text: t('risk:sorting.rating-desc'),
        value: 'rating-desc',
      },
      {
        id: 'rating-asc',
        text: t('risk:sorting.rating-asc'),
        value: 'rating-asc',
      },
    ],
    [t],
  );
  const [selectedSort, setSelectedSort] = useState<Option<string, string>>(sortingOptions[0]);

  useEffect(() => {
    // Update language shown
    setSelectedSort((prev) => sortingOptions.find((x) => x.id === prev.id) || sortingOptions[0]);
  }, [language, sortingOptions]);

  const ratingOptions = useMemo(() => {
    return [allRiskOption, ...riskRatingOptions(t)];
  }, [allRiskOption, t]);

  useEffect(() => {
    // Update language shown
    setSelectedRatingFilter((prev) => ratingOptions.find((x) => x.id === prev.id) || allRiskOption);
  }, [allRiskOption, language, ratingOptions]);

  const filteredRisk = useMemo(() => {
    const filteredRisk = risks.filter((x) => (selectedRatingFilter.value ? x.riskRating === selectedRatingFilter.value : true));
    return [...filteredRisk].sort((a, b) => {
      switch (selectedSort?.value) {
        case 'recent':
          return new Date(b.lastModifiedUtc).getTime() - new Date(a.lastModifiedUtc).getTime();
        case 'id-asc':
          return a.friendlyId - b.friendlyId;
        case 'id-desc':
          return b.friendlyId - a.friendlyId;
        case 'rating-desc':
          return b.riskRating - a.riskRating;
        case 'rating-asc':
          return a.riskRating - b.riskRating;
        default:
        case 'default':
          return a.actionIndex - b.actionIndex;
      }
    });
  }, [risks, selectedRatingFilter.value, selectedSort?.value]);

  return (
    <div className="my-4">
      <div className="sticky top-0 z-50 flex items-center justify-between border-b bg-white px-4 pb-2">
        <div className="font-medium">{t('left-tabs.risks.heading')}</div>
        <div className="flex items-center gap-1">
          <div className="w-28">
            <DropdownSelect
              value={selectedSort}
              options={sortingOptions}
              onChange={(option) => {
                setSelectedSort(option);
              }}
              size={DropdownSize.S}
            />
          </div>
          <div className="w-28">
            <DropdownSelect
              value={selectedRatingFilter}
              options={ratingOptions}
              onChange={(option) => {
                setSelectedRatingFilter(option);
              }}
              size={DropdownSize.S}
            />
          </div>
        </div>
      </div>
      {filteredRisk.map((risk) => (
        <CondencedRiskItem key={risk.id} risk={risk} viewOnly={viewOnly} />
      ))}
      {filteredRisk.length === 0 && <div className="flex h-48 items-center justify-center">{t('left-tabs.risks.empty-state')}</div>}
    </div>
  );
};

export default CondensedRisks;
